@import "../variables_custom.scss";

::ng-deep #ngwizard.wortach>ul>li>a {
  border-radius: 0% !important;
  color: grey !important;
  background: rgba($wortach-color__main, 0.5) !important;
}


#applyBtn.wortach,
::ng-deep #ngwizard.wortach>div.btn-toolbar>div.btn-group>button {
  background-color: rgba($wortach-color__main, 0.3);
  border: 2px solid $wortach-color__main;
}

#applyBtn.wortach:hover,
::ng-deep #ngwizard.wortach>div.btn-toolbar>div.btn-group>button:hover {
  background-color: rgba($wortach-color__main, 0.7);
}

/* Customers Tree Overrides */

::ng-deep .wortach-main-bgcolor {
  background-color: $wortach-color__main;
}

::ng-deep .wortach .dx-treeview-item:hover {
  background-color: rgba($wortach-color__main, 0.2) !important;
}

::ng-deep .wortach .dx-treeview-item-without-checkbox.dx-state-focused>.dx-treeview-item {
  background-color: $wortach-color__main !important;
}

::ng-deep .wortach .dx-list-item:hover {
  background-color: rgba($wortach-color__main, 0.2) !important;
}
::ng-deep .wortach .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
  background-color: rgba($wortach-color__main, 0.2) !important;
  color: #333;
}

.wortach .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.wortach .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date{
    box-shadow: inset 0 0 0 1px #bebebe, inset 0 0 0 1000px rgba($wortach-color__main, 0.6);
}