@import "../variables_custom.scss";

.item-selected.chrono {
  background-color: rgba($chrono-color__main, 0.5);
  cursor: pointer;
}

.nav-item.chrono:not(.item-selected):hover,
.item-active.chrono {
  background-color: rgba($chrono-color__main, 0.5);
  cursor: pointer;
}

::ng-deep .mat-menu-panel .menu-item.chrono:hover {
    background-color: rgba($chrono-color__main, 0.5) !important;
}
