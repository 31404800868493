@import "../variables_custom.scss";

.item-selected.dms {
  background-color: rgba($dms-color__main, 0.5);
  cursor: pointer;
}

.nav-item.dms:not(.item-selected):hover,
.item-active.dms {
  background-color: rgba($dms-color__main, 0.5);
  cursor: pointer;
}

::ng-deep .mat-menu-panel .menu-item.dms:hover {
    background-color: rgba($dms-color__main, 0.5) !important;
}
