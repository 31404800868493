@import "../variables_custom.scss";

::ng-deep #ngwizard.record>ul>li>a {
  border-radius: 0% !important;
  color: black !important;
  background: rgba($record-color__main, 0.5) !important;
}


#applyBtn.record,
::ng-deep #ngwizard.record>div.btn-toolbar>div.btn-group>button {
  background-color: rgba($record-color__main, 0.3);
  border: 2px solid $record-color__main;
}

#applyBtn.record:hover,
::ng-deep #ngwizard.record>div.btn-toolbar>div.btn-group>button:hover {
  background-color: rgba($record-color__main, 0.7);
}

/* Customers Tree Overrides */

::ng-deep .record-main-bgcolor {
  background-color: $record-color__main;
}

::ng-deep .record .dx-treeview-item:hover {
  background-color: rgba($record-color__main, 0.2) !important;
}

::ng-deep .record .dx-treeview-item-without-checkbox.dx-state-focused>.dx-treeview-item {
  background-color: $record-color__main !important;
}

::ng-deep .record .dx-list-item:hover {
  background-color: rgba($record-color__main, 0.2) !important;
}
::ng-deep .record .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
  background-color: rgba($record-color__main, 0.2) !important;
  color: #333;
}

.record .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.record .dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today.dx-calendar-contoured-date{
    box-shadow: inset 0 0 0 1px #bebebe, inset 0 0 0 1000px rgba($record-color__main, 0.6);
}

::ng-deep .record .dx-treeview-item-without-checkbox.dx-state-focused>.dx-treeview-item.record-main-bgcolor span,
::ng-deep .record .dx-treeview-item-without-checkbox>.dx-treeview-item.record-main-bgcolor  span{
  color:white!important;
}
