.mat-dialog-container{
    padding: 0 !important;
}

.cdk-overlay-pane{
    position: absolute !important;
    top: 30px !important;
}

.card{
    border: none !important;
}

.card-footer{
    background-color: white;
    display: flex;
    .btn-yes{
        margin-left: auto;
        color: #fff !important;
        background-color: #008cba;
        border-color: #0079a1;
        margin-right: 5px;
    }
    .btn-no{
        color: #fff !important;
        background-color: #ffd187;
        border-color: #ffc76e;
    }

    .btn-cancel{
        color: #fff !important;
        background-color: #750303;
        border-color: #f03444;
        margin-left: 5px;
    }

    .btn-ok{
        color: #fff !important;
        background-color: #008cba;
        border-color: #0079a1;
        margin-left: auto;
    }
    .btn {
        padding: 5px 10px;
        text-transform: uppercase;
        border-radius: 0;
        font-size: 14px;
    }
    .btn:hover{
        opacity: unset !important;
    }
}

.card-header{
    margin: 0 !important;
}

p{
    color: #222222;
}
