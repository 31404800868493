/*********************************************************************
**********************  ATS CUSTOM STYLESHEET  *********************
*********************************************************************/

/*********************************************************************
*****************************  GENERIC  ******************************
*********************************************************************/
// Colors
$basecolor: #005d98;

$dierre-color__main: $basecolor;

/*$aft-login__text__color: $basecolor;*/

$dierre-dx-grid__selected-row__background-color: rgba($basecolor, 0.2);


$dierre-logo: url('../images/LogoCustomer.png');
$dierre-logo-small: url('../images/LogoCustomer_black.png');

//Login
$dierre-login__background: url('./images/login-background-2.jpg');

$dierre-login-button__color: #FFF;

$dierre-selected-text__color: #FFF;