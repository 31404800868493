@import "../variables_custom.scss";

table.dms td.active{
    background-color: $dms-color__main;
    span > p {
        color: white !important;
    }
}

.dms.fa-sign-out{
    background-color: $dms-color__main;
    color: white;
}

.activeMapButton-dms{
    background-color: $dms-color__main!important;
}

.dms-map-totalJourneys-table {
    width: 100%;
    height: 80px;
    box-shadow: 0px 0px 2px rgba($dms-color__main, 0.7);
    border: 2px solid rgba($dms-color__main, 0.7);
    font-size: 12px;
    tr > td {
        i {
            color: $dms-color__main;
        }
    }
}