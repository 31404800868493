.geocoder-control-input{
    position:absolute;
    left:0;
    top:0;
    background-color:white;
    background-repeat:no-repeat;
    background-image:url("../images/search.png");
    background-size:26px;
    border:none;
    padding:0;
    text-indent:6px;
    font-size:13px;
    line-height:normal;
    height:auto;
    padding-top:5px;
    padding-bottom:5px;
    width:100%;
    background-position:right center;
    cursor:pointer;
    box-sizing:border-box
}

.geocoder-control-input-disabled{
    background-color:#f4f4f4;
    background-image:url("../images/search-disabled.png")
}

.geocoder-control{
    width:26px;
    height:26px;
    -webkit-transition:width .175s ease-in;
    -moz-transition:width .175s ease-in;
    -ms-transition:width .175s ease-in;
    -o-transition:width .175s ease-in;
    transition:width .175s ease-in
}
.geocoder-control-expanded,.leaflet-touch .geocoder-control-expanded{
    width:275px
}

.geocoder-control-input.geocoder-control-loading{
    background-image:url("../images/loading.gif");
    background-size:26px
}

@media only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2){
    .geocoder-control-input{
        background-image:url("../images/search@2x.png")
    }
    .geocoder-control-input-disabled{
        background-image:url("../images/search@2x-disabled.png")
    }
    .geocoder-control-input.geocoder-control-loading{
        background-image:url("../images/loading@2x.gif")
    }
}

.geocoder-control-input:focus{
    outline:none;cursor:text
}

.geocoder-control-input::-ms-clear{
    display:none
}

.geocoder-control-suggestions{
    width:100%;
    position:absolute;
    top:26px;
    left:0;
    margin-top:10px;
    overflow:auto;
    display:none
}

.geocoder-control-list+.geocoder-control-header{
    border-top:1px solid #d5d5d5
}

.geocoder-control-header{
    font-size:10px;
    font-weight:700;
    text-transform:uppercase;
    letter-spacing:0.05em;
    color:#444;
    background:#F2F2F2;
    border-bottom:1px solid #d5d5d5;
    display:block;padding:.5em
}

.geocoder-control-list{list-style:none;margin:0;padding:0}

.geocoder-control-suggestions .geocoder-control-suggestion{
    font-size:13px;
    padding:7px;
    background:white;
    border-top:1px solid #f1f1f1;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    cursor:pointer;
    color: rgb(59, 59, 59);
}

.geocoder-control-suggestions .geocoder-control-suggestion:first-child{
    border:none
}

.geocoder-control-suggestions .geocoder-control-suggestion.geocoder-control-selected,.geocoder-control-suggestions .geocoder-control-suggestion:hover{
    background:#BDD249;
    border-color:#BDD249;
    color: white;
}

.leaflet-right .geocoder-control-suggestions{
    left:auto;
    right:0
}

.leaflet-right .geocoder-control-input{
    left:auto;
    right:0
}

.leaflet-bottom .geocoder-control-suggestions{
    margin-top:0;
    top:0
}

.leaflet-touch .geocoder-control{
    width:34px
}

.leaflet-touch .geocoder-control.geocoder-control-expanded{
    width:275px
}

.leaflet-touch .geocoder-control-input{
    height:34px;
    line-height:30px;
    background-size:30px
}

.leaflet-touch .geocoder-control-suggestions{
    top:30px;
    width:271px
}

.leaflet-oldie .geocoder-control-input{
    width:28px;
    height:28px
}

.leaflet-oldie .geocoder-control-expanded .geocoder-control-input{
    width:auto
}

.leaflet-oldie .geocoder-control-input,.leaflet-oldie .geocoder-control-suggestions{border:1px solid #999}

.leaflet-routing-geocoders div {
    padding: 0px!important;

}

.aft-float-rightBtn {
    //margin-top: 5px;
    //margin-bottom: 5px;
    float: right!important;
}

.leaflet-routing-geocoders input{
    width: 100%!important;
}

.leaflet-routing-geocoders button {
    float: left!important;
    font: bold 18px 'Lucida Console', Monaco, monospace;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    margin-top: 10px;
    margin-right: 3px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}


/*.leaflet-routing-geocoders button.leaflet-routing-add-waypoint {
    margin-top: 10px;
}*/
.aft-float-rightBtn .dx-switch-container {
    height: 18px!important;
}

.aft-float-rightBtn .dx-switch-handle {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-left: 1px;
}

.aft-float-rightBtn .dx-switch-off, .aft-float-rightBtn .dx-switch-on {
    float: left;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 50%;
    padding-right: 14px!important;
    line-height: 16px;
    text-align: center;
    font-size: 9px;
    font-weight: 600;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.aft-float-rightBtn.dx-switch-on-value .dx-switch-handle {
    transform: translate(-114%)!important;
}

.leaflet-routing-geocoders button.leaflet-routing-reverse-waypoints {
    border: 1px solid #ccc!important;
}

.aft-margin-top-5{
    margin: 5px 0 20px 0;
}
