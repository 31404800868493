@import "../variables_custom.scss";

.item-selected.wortach {
  background-color: rgba($wortach-color__main, 0.5);
  cursor: pointer;
}

.nav-item.wortach:not(.item-selected):hover,
.item-active.wortach {
  background-color: rgba($wortach-color__main, 0.5);
  cursor: pointer;
}

::ng-deep .mat-menu-panel .menu-item.wortach:hover {
    background-color: rgba($wortach-color__main, 0.5) !important;
}
