@import "../variables_custom.scss";

.item-selected.siak {
  background-color: rgba($siak-color__main, 0.5);
  cursor: pointer;
}

.nav-item.siak:not(.item-selected):hover,
.item-active.siak {
  background-color: rgba($siak-color__main, 0.5);
  cursor: pointer;
}

::ng-deep .mat-menu-panel .menu-item.siak:hover {
    background-color: rgba($siak-color__main, 0.5) !important;
}
