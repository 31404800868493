/*********************************************************************
**********************  ATS CUSTOM STYLESHEET  *********************
*********************************************************************/

/*********************************************************************
*****************************  GENERIC  ******************************
*********************************************************************/
// Colors
$basecolor: rgb(231, 115, 7);

$chrono-color__main: $basecolor;

/*$aft-login__text__color: $basecolor;*/

$chrono-dx-grid__selected-row__background-color: rgba($basecolor, 0.2);

$chrono-logo: url('../images/LogoCustomer.png');
$chrono-logo-small: url('../images/LogoCustomer_black.png');

//Login
$chrono-login__background: url('./images/login-background-2.jpg');

$chrono-login-button__color: #FFF;

$chrono-selected-text__color: #FFF;