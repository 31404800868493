/*********************************************************************
**********************  ATS CUSTOM STYLESHEET  *********************
*********************************************************************/

/*********************************************************************
*****************************  GENERIC  ******************************
*********************************************************************/
// Colors
$basecolor: rgb(213, 19, 23);

$intellic-color__main: $basecolor;

/*$aft-login__text__color: $basecolor;*/

$intellic-dx-grid__selected-row__background-color: rgba($basecolor, 0.2);

$intellic-logo: url('../images/LogoCustomer.png');
$intellic-logo-small: url('../images/LogoCustomer_black.png');

//Login
$intellic-login__background: url('./images/login-background-2.jpg');

$intellic-login-button__color: #FFF;

$intellic-selected-text__color: #FFF;