@import "../variables_custom.scss";

table.intellic td.active{
    background-color: $intellic-color__main;
    span > p {
        color: white !important;
    }
}

.intellic.fa-sign-out{
    background-color: $intellic-color__main;
    color: white;
}

.activeMapButton-intellic{
    background-color: $intellic-color__main!important;
}

.intellic-map-totalJourneys-table {
    width: 100%;
    height: 80px;
    box-shadow: 0px 0px 2px rgba($intellic-color__main, 0.7);
    border: 2px solid rgba($intellic-color__main, 0.7);
    font-size: 12px;
    tr > td {
        i {
            color: $intellic-color__main;
        }
    }
}