.dx-pager .dx-page-sizes .dx-page-size {
    padding: 5px !important;
    font-size: 12px !important;
}

.dx-pager .dx-pages .dx-info{
    font-size: 12px;
}

.dx-pager .dx-pages .dx-page {
    padding: 5px !important;
    font-size: 12px !important;
}

.dx-pager .dx-pages .dx-navigate-button{
    padding: 7px 10px !important;
}

.dx-pager .dx-pages .dx-next-button, .dx-pager .dx-pages .dx-prev-button{
    font-size: 15px !important;
}

.informer {
    font-weight: 500;
}

.dx-datagrid-rowsview .dx-row-removed > td {
    background-color: rgba(255, 68, 68, 0.7);
    border-bottom: 1px solid rgba(255, 68, 68, 0.7);
}

.panel-icons-btn{
    .dx-button-mode-contained.dx-button-success {
        background-color: #e7e7e7 !important;
        border-color: #cccccc !important;
        color: #3e3f3a !important;
        border-radius: 0 !important;
        .dx-icon {
            color: #333 !important;
        }
        .dx-button-content {
            padding: 5px 3px;
        }
    }
}


.dx-item-content{
    font-size: 12px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select{
    height: 28px;
}

.dx-invalid.dx-texteditor {
    border-color: rgba(217,83,79,.4) !important;
}


.dx-multiview-wrapper{
    padding: 10px;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    padding: 2px 4px !important;
}


.dx-treeview {
    height: 95%;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected{
    background-color: #008c95b6;
}

.dx-accordion-item-title::before{
    content: '' !important;
}

.dx-accordion-item-opened {
    border: 2px solid #008c95b6;
}

.dx-radiogroup-vertical .dx-collection {
    display: block !important;
}

.dx-datagrid-search-panel{
    margin: 0;
}

.dx-fileuploader-input-wrapper::after, .dx-fileuploader-input-wrapper::before{
    content: none !important;
}