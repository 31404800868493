@import "../variables_custom.scss";

.dierre-pref-btn {
    color: black;
    background-color: rgba($dierre-color__main, 0.5);
    border: 1px solid $dierre-color__main;
  }
  
  .dierre-pref-btn:hover {
    background-color: $dierre-color__main;
  }