/*********************************************************************
**********************  ACTIA CUSTOM STYLESHEET  *********************
*********************************************************************/

/*********************************************************************
*****************************  GENERIC  ******************************
*********************************************************************/

// Fonts
$atf-font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$aft-development-box__font-size: 10px;
$aft-panel-dashboard__panel-heading__font-size: 15px;
$aft-invalid-message__icon__font-size: 11px;
$aft-invalid-message__font-size: 11px;

// Borders like
$aft-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$aft-invalid__border: 1px solid rgba(217,83,79,.4);
$aft-panel-dashboard__box-shadow: $aft-box-shadow;


// Colors
$aft-color__main: #008c95b6 !default;
$wortach-color__main: #008c95b6 !default;
$aft-text__color: #3e3f3a !default;
$aft-textmain__color: #FFF !default;

$aft-selected-text__color: $aft-text__color !default;
$aft-customer__color: #546e7a;
$aft-vehicles__color:#4db6ac;
$aft-drivers__color: #994177;
/*$aft-text-danger__color: #f04124;
$aft-text-warning__color: #e99002;
$aft-text-success__color: #43ac6a;*/

$aft-text-danger__color: #ff4444;
$aft-text-warning__color: #ffbb33;
$aft-text-success__color: #00C851;

$aft-text-danger__darkcolor: #CC0000;
$aft-text-warning__darkcolor: #FF8800;
$aft-text-success__darkcolor: #007E33;

/*$aft-dx-text-danger__color: #f04124;
$aft-dx-text-warning__color: #e99002;
$aft-dx-text-success__color: #358753;*/

$aft-dx-text-danger__color: #ff4444;
$aft-dx-text-warning__color: #ffbb33;
$aft-dx-text-success__color: #00C851;

$aft-skewed-edge__border-color: #FFD800;

$aft-dx-bg-primary__background-color: #008cba;
$aft-dx-switch__color: rgb(51, 51, 51);
$aft-dx-grid__selected-row__background-color: rgba($aft-color__main, 0.2) !default;
$aft-dx-checkbox__border-color: #c0c0c0;


$aft-loading-spinner-container__background-color: #FFF;

$aft-development-box__background-color: orangered;
$aft-development-box__color: #FFF;

$btn-square-xs__color: #3e3f3a;

$aft-panel-dashboard__background: #FFF;

$aft-panel-dashboard__panel-heading__background-color: #FFF;

$aft-invalid-message__icon__background-color: #d9534f;
$aft-invalid-message__icon__color: #FFF;

$aft-visible-xs__border-color: #DDD;


$aft-map__icon-keyoff-color: #7d7d86;

$aft-upload-area-border-color: #959595;

// Size
$aft-skewed-edge__border-width: 10px;
$btn-square-xs__width: 30px;
$btn-square-xs__height: 30px;

$btn-square-md__width: 38px;
$btn-square-md__height: 38px;

$aft-panel-dashboard--lg__height: 521px;
$aft-panel-dashboard--sm__height: 250px;

$angular-animate-switch-container__height: 300px;

$aft-invalid-message__icon__width: 16px;
$aft-invalid-message__icon__height: 16px;

$aft-upload-area-border-size: 2px;

// Actia logo
$aft-header__logo__actia__color: #3e3f3a;
$aft-header__logo__actia__font-size: 20px;
$aft-header__logo__actia__font-style: italic;
$aft-header__logo__actia__font-weight: bold;

$aft-header__logo__fleet__color: $aft-color__main;
$aft-header__logo__fleet__font-size: 18px;
$aft-header__logo__fleet__font-weight: bold;


// Cookie
$aft-cookie__background-color: rgb(123, 172, 65);
$aft-cookie__background-color--transparent: rgba(123, 172, 65, 0.8);

$aft-cookie__button__width: 75px;

$aft-cookie__msg__color: #FFF;


// Header
$aft-navbar__background-color: #FFF;
$aft-navbar__box-shadow: $aft-box-shadow;

$aft-header__navbar__height: 45px;

$aft-header__dropdown-toggle__color: #3e3f3a;
$aft-header__dropdown-menu__item__color: $aft-header__dropdown-toggle__color;
$aft-header__dropdown-menu__item__font-weight: bold;
$aft-header__dropdown-menu__item__padding: 5px 10px;

$aft-header__sidebar-toggle__font-size: 18px;
$aft-header__sidebar-toggle__color: #3e3f3a;


/*********************************************************************
*****************************  MODULES  ******************************
*********************************************************************/

// Login
$aft-login__background: url('images/login-background-2.jpg');

$aft-login__text__color: #FFF !default;
$aft-login__footer__menu__text__color: #FFF !default;

$aft-header__logo--login__actia__color: #FFF;
$aft-header__logo--login__actia__font-size: 40px;

$aft-header__logo--login__fleet__font-size: $aft-header__logo--login__actia__font-size;

$aft-login__footer__menu__font-size: 15px;
$aft-login__footer__menu--xs__font-size: 10px;

$aft-strength__bar__point__background-color: #DDD;


// Grid toolbar
$aft-grid-toolbar__title__font-size: 20px;
$aft-grid-toolbar__title__font-weight: 200;
$aft-grid-toolbar__title__fill: #232323;


// Sidebar
$aft-sidebar__background-color: #ffffff;
$aft-sidebar__font-color: $aft-text__color;
$aft-sidebar-selected__font-color: $aft-selected-text__color !default;
$aft-sidebar__hover__background-color: rgba($aft-color__main, 0.4); // need hex value
$aft-sidebar__box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
$aft-sidebar__width: 70px;

$aft-sidebar__active-link__background-color: $aft-color__main;

$aft-sidebar__first-level__label__line-height: 10px;
$aft-sidebar__first-level__label__font-size: 10px;

$aft-sidebar__icon__font-size: 20px;

$aft-sidebar__second-level__item__line-height: 15px;

$aft-sidebar__second-level__item__label__font-size: 11px;

$aft-sidebar__new-tab__item__font-size: 11px;
$aft-sidebar__new-tab__item__line-height: 10px;


// Features
$aft-feature__title__font-size: 13px;

$aft-functionality__name__font-size: 12px;
$aft-feature__header__label__font-size: $aft-functionality__name__font-size;

$aft-functionality__border: 1px solid lightgray;

$aft-functionality__font-weight: bold;

$aft-functionality__description__font-size: 11px;
$aft-functionality__description__font-weight: normal;


/*// Fleet status
$aft-fleet-status__card__background: #FFF;

$aft-fleet-status__card--z1__box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$aft-fleet-status__card--z2__box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$aft-fleet-status__card--z3__box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$aft-fleet-status__card--z4__box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
$aft-fleet-status__card--z5__box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);*/


// Map icons
$aft-map__icon__font-size: 22px;
$aft-map__base-icon__width: 40px;
$aft-map__base-icon__height: 40px;
$aft-map__base-icon__box-shadow: 2px 2px 5px #888888;
$aft-map__icon__arrow--ko__color: #7E7E87;
$aft-map__icon__arrow--ok__color: $aft-text-success__color;
$aft-map__icon__arrow--warn__color: $aft-text-warning__color;
$aft-map__icon__arrow--ko__bg: #7E7E87;
$aft-map__icon__arrow--ok__bg: $aft-text-success__color;
$aft-map__icon__arrow--warn__bg: $aft-text-warning__color;
$aft-map-vehicles-accordion__width: 300px;
$aft-map__background-color: #FFF;
// Home
$aft-home__background-color: #f1f1f1;
$aft-home__version__font-size: 10px;


// Oops
$aft-oops__background-image: url('images/oops.jpg');
$aft-oops__container__background-color: rgba(255, 255, 255, 0.8);
$aft-oops__font-weight: bold;


// Page header
$aft-page-header__color: #3e3f3a;

$aft-page-header__title__font-size: 26px;

$aft-page-header__filter-legend__font-size: 13px;

$aft-page-header__helper-container--on__height: 200px;

$aft-page-header__helper-container__max-height: $aft-page-header__helper-container--on__height;
$aft-page-header__helper-container__box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

$aft-page-header__panel-heading__background-color: #EAF7FF;

$aft-page-header__panel-body__background-color: #FFF;

$aft-page-header__filter__box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);


// Account
$atf-account_pwd-indicator__label__color: #FFF;


// Driver
$aft-driver__card-id__prefix__width: 38px;
$aft-driver_card-id-prefix-space: 8px;


// Group
$aft-group__list__height: 394px;


// Tacho
$aft-tacho__breadcrumb__font-size: 16px;

$aft-tacho__week-biweek__table__even__background-color: #f9f9f9;
$aft-tacho__week-biweek__table__odd__background-color: #f0f0f0;
$aft-tacho__week-biweek__date__font-size: 16px;

$aft-tacho__week-biweek__table__wrapping-row__background-color: #FFF;

$aft-tacho__label__font-size: 16px;
$aft-tacho__label__font-style: italic;

$aft-tacho__label--big__font-size: 24px;

$aft-tacho__title-Infrigments__font-size: 14px;

$aft-tacho__title__font-size: 16px;

$aft-tacho__daily__cell__width: 14.28%;

/*$aft-tacho__driving__color: #E49D91;
$aft-tacho__working__color: #F27CFF;
$aft-tacho__resting__color: #ACDC90;
$aft-tacho__available__color: #77ADD0;*/
$aft-tacho__driving__color: #FDAF3B;
$aft-tacho__working__color: #B666C2;
$aft-tacho__resting__color: #82D555;
$aft-tacho__available__color: #68C8ED;
// Customer
$aft-customer-tree__list__height: 391px;

//HOME
$aft-home__fuel__color: #E51162;
$aft-home__distance__color: #0D47A1;
$aft-home__avgcons__color: #FF6D00;

//FUEL
$aft-fuel__fuel__color: #82D555;
$aft-fuel__distance__color: #D4BC23;
$aft-fuel__avgcons__color: #FDAF3B;

// Filter
$aft-filter__select-label__border-bottom: 1px solid #3e3f3a;

$aft-filter__time-span-chooser__label__font-size: 13px;


// Gallery
$aft-gallery__arrow__background-color: $aft-color__main;
$aft-gallery__arrow__color: #FFF;
$aft-gallery__arrow__font-size: 19px;

$aft-gallery__exit__background-color: $aft-color__main;
$aft-gallery__exit__color: #FFF;
