@import "../variables_custom.scss";

.intellic-pref-btn {
    color: black;
    background-color: rgba($intellic-color__main, 0.5);
    border: 1px solid $intellic-color__main;
  }
  
  .intellic-pref-btn:hover {
    background-color: $intellic-color__main;
  }