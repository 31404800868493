﻿/*********************************************************************
*************************  HEADER STYLESHEET  ************************
*********************************************************************/
@import '../variables_custom.scss';
@import '../../../variables.scss';

.dms-header__logo--login {
    background: $dms-logo no-repeat;
    height: 70px;
}

.dms-header__logo__wrapper {
    float: left;
    margin-top: -10px;
    margin-left: 30px;
    width: 118px;
    padding-top: 8px;
}


.dms-header__logo {
    background: $dms-logo-small no-repeat;
    height: 30px;
    width: 180px;
    opacity: 0;
    animation-name: aft-header;
    animation-delay: 0.8s;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    cursor: pointer;
    background-size: contain;
    background-position: -46px center;
}

/*.dms-header__logo {
    background: $dms-logo-small no-repeat;
    background-size: contain;
    height: 21px;
    width: 64px;
    margin-top: -3px;
}*/

.dms-header__dropdown-menu {
    &.dropdown-menu {
        > li {
            > a,
            > a:focus,
            > a:active,
            > a:hover {
                color: $aft-header__dropdown-menu__item__color;
                font-weight: $aft-header__dropdown-menu__item__font-weight;
            }
        }
        > li:active {
            background-color: rgba($dms-color__main, 0.5);
        }

        @media (max-width: 767px) {
            left: auto;
            right: 0;
            position: absolute;
            margin: 4px 0 0;
        }
    }
}
.dms .help-btn{
    background-color: rgba($dms-color__main, 0.7)!important;
}
  
.dms  .help-btn:hover{
    color: #3e3f3a !important
}