/*********************************************************************
**********************  ATS CUSTOM STYLESHEET  *********************
*********************************************************************/

/*********************************************************************
*****************************  GENERIC  ******************************
*********************************************************************/

@import "../../variables.scss";

// Colors
$basecolor: $wortach-color__main;

$wortach-color__main: $basecolor;

$wortach-login__text__color: $basecolor;

$wortach-dx-grid__selected-row__background-color: rgba($basecolor, 0.2);

$wortach-logo: url('../images/LogoCustomer.png');

$wortach-logo-small: url('../images/LogoCustomer_black.png');

//Login
$wortach-login__background: url('./images/login-background-2.jpg');

$wortach-login-button__color: #FFF;

$wortach-selected-text__color: #FFF;