@import "../variables_custom.scss";

.item-selected.intellic {
  background-color: rgba($intellic-color__main, 0.5);
  cursor: pointer;
}

.nav-item.intellic:not(.item-selected):hover,
.item-active.intellic {
  background-color: rgba($intellic-color__main, 0.5);
  cursor: pointer;
}

::ng-deep .mat-menu-panel .menu-item.intellic:hover {
    background-color: rgba($intellic-color__main, 0.5) !important;
}
