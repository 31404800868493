﻿/*********************************************************************
**************************  LOGIN STYLESHEET  ************************
*********************************************************************/
@import '../variables_custom.scss';

.wortach-login__button {
    margin-top: 15px;
    color: $wortach-login-button__color;
    background-color: $wortach-color__main;
    border-color: $wortach-color__main;
    font-weight: bold;
}

.wortach-login {
    background-image: $wortach-login__background;
    background-position: 100% 0;
    -webkit-background-size: cover;
    background-size: cover;
    overflow-y: auto;
    height: 100vh;
    width: 100vw;
}

footer {
    color: white;
}

.wortach-login__title {
    color: white;
    font-weight: 300;
}

.wortach-login__button:hover,
.wortach-login__button:active,
.wortach-login__button:focus,
.wortach-login__button:hover:active {
    color: $wortach-login-button__color !important;
    background-color: $wortach-color__main !important;
    border-color: $wortach-login-button__color !important;
}



.wortach-login__container-wrapper {
    background: none;
    width: 600px;
}

.wortach-login__reset-pwd-cursor {
    .dx-slider-handle {
        background-color: $wortach-color__main !important;
    }

    .dx-slider-range-visible {
        border-color: $wortach-color__main !important;
    }
}

::ng-deep dx-slider.wortach .dx-slider-handle {
    background-color: $wortach-color__main !important;
}

::ng-deep dx-slider.wortach > div > div > div.dx-slider-range {
    border: 1px solid $wortach-color__main !important;
    background: rgba($wortach-color__main, 0.7) !important;
} 