﻿/*********************************************************************
**************************  LOGIN STYLESHEET  ************************
*********************************************************************/
@import '../variables_custom.scss';

.dms-login__button {
    margin-top: 15px;
    color: $dms-color__main;
    background-color: white;
    border-color: $dms-color__main;
    font-weight: bold;
}

.atf-account__captcha-label__container p {
    margin: 10px 0;
}

.dms-p {
    color: black !important;
}

.dms-login {
    background-image: $dms-login__background;
    background-position: 100% 0;
    -webkit-background-size: cover;
    background-size: cover;
    overflow-y: auto;
    height: 100vh;
    width: 100vw;
}

.aft-login__reset-pwd.dms {
    color: $dms-color__main;
}

footer {
    color: white;
}

.dms-login__title {
    color: black;
    font-weight: 300;
}

.dms-login__button:hover,
.dms-login__button:active,
.dms-login__button:focus,
.dms-login__button:hover:active {
    color: $dms-login-button__color !important;
    background-color: $dms-color__main !important;
    border-color: $dms-login-button__color !important;
}



.dms-login__container-wrapper {
    background: none;
    width: 600px;
}

.dms-login__reset-pwd-cursor {
    .dx-slider-handle {
        background-color: $dms-color__main !important;
    }

    .dx-slider-range-visible {
        border-color: $dms-color__main !important;
    }
}

.dms-login__container-wrapper{
    height: 100vh;
}


@media screen and  (min-width: 767px){
    .dms-login__container-wrapper {
        background: rgba(0, 0, 0, 0.5);
        width: 600px;
    }
}

::ng-deep dx-slider.dms .dx-slider-handle {
    background-color: $dms-color__main !important;
}

::ng-deep dx-slider.dms > div > div > div.dx-slider-range {
    border: 1px solid $dms-color__main !important;
    background: rgba($dms-color__main, 0.7) !important;
} 
