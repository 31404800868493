/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "./assets/styles/general.scss";
@import "./assets/styles/variables.scss";
@import "./assets/styles/scrollbar.scss";
@import "./assets/styles/popover.scss";
@import "./assets/styles/form.scss";
@import "./assets/styles/dialog.scss";
@import "./assets/styles/mat-menu.scss";
@import "./assets/styles/custom-dx.scss";
@import "./assets/styles/easy-button.scss";
@import "./assets/styles/geocoder.scss";
@import "./assets/styles/leaflet.scss";
@import '~bootstrap/scss/bootstrap';
@import "~jsoneditor/dist/jsoneditor.min.css";

@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css";


//@import '~ng-wizard/themes/ng_wizard.min.css';

html,
body {
    height: 100%;
}

:root {
    --modal-color-info: #099099;
    --pad-left: 25px;
    --pad-top: 13px;
}

body {
    background-color: #f1f1f1 !important;
    margin: 0;
    font-family: Roboto,
        "Helvetica Neue", sans-serif;
}

.report-container {
    height: 100vh;
    margin: 8px auto;
    width: 100%;
}

.mat-menu-panel .menu-item {
    font-size: 12px !important;
    padding: 6px 5px !important;
}

.report-container-messages {
    height: 70vh;
}

// .report-container-tacho {
//     width: 100%;
//     height: 100vh;
// }

@media screen and (min-width:1300px) {
    .report-container-messages-2 {
        height: 70vh;
        margin-top: -100px;
        margin-bottom: -250px;
    }
}

@media screen and (min-width:1050px) and (max-width:1300px) {
    .report-container-messages-2 {
        height: 70vh;
        margin-bottom: -250px;
    }
}


.report-container-tacho {
    height: calc(100vh - 390px);
    margin: 8px auto;
    width: 100%;

    >iframe {
        height: 100vh;
    }
}

.report-container-tacho2 {
    width: 100%;
    height: 100vh;
}

.aft-panel-dashboard {
    border-radius: 11px;
}

.dx-switch-container {
    border-radius: 12px !important;
}


.dx-switch-handle:before {
    border-radius: 10px !important;
    background-color: #99a2a3 !important;
}

.dx-switch-on-value .dx-switch-handle:before {
    background-color: #49adb3 !important;
}

.dx-switch.dx-state-hover .dx-switch-container {
    border-color: #99a2a3 !important;
}

.dx-switch.dx-state-hover .dx-switch-handle:before {
    background-color: #008c95 !important;
}

.dx-switch.dx-stat-focused .dx-switch-handle:before {
    background-color: #008c95 !important;
}


@media screen and (max-width:1050px) {
    .report-container-messages-2 {
        height: calc(100vh - 390px);
        margin: 8px auto;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;

        >iframe {
            width: 1000px !important;
            height: 150% !important;
        }
    }
}

@media screen and (max-width:1300px) {
    .report-container-messages {
        height: calc(100vh - 390px);
        margin: 8px auto;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;

        >iframe {
            width: 1000px !important;
            height: 100%;
        }
    }

    .report-container-tacho {
        height: calc(100vh - 390px);
        margin: 8px auto;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;

        >iframe {
            width: 1000px !important;
            height: 100%;
        }
    }

    .report-container-tacho2 {
        height: calc(100vh - 390px);
        margin: 8px auto;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;

        >iframe {
            width: 1000px !important;
            height: 100%;
        }
    }

    .report-container {
        height: calc(100vh - 390px);
        margin: 8px auto;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;

        >iframe {
            width: 1000px !important;
            height: 100%;
        }
    }
}

.mat-drawer-inner-container {
    height: auto !important;
}

#assetsTab .dx-checkbox-indeterminate .dx-checkbox-icon::before {
    background-color: transparent;
}

.filter-wrapper .dx-scrollable-content>.dx-treeview-node-container {
    max-height: calc(100vh - 320px) !important;
    overflow: auto !important;
}

#listAssets .dx-scrollable-wrapper {
    max-height: calc(100vh - 370px);
    overflow: auto;
}

@media screen and (max-width: 1340px) {
    .tableUserPreferences {
        tr {
            td {
                &:nth-child(2) {
                    .dx-widget .dx-collection {
                        display: block !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 632px) {
    .cdk-overlay-connected-position-bounding-box {
        top: 70px !important;
    }
}

#accordionJourney .dx-accordion-wrapper>.dx-item.dx-accordion-item.dx-accordion-item-closed {
    height: auto !important;
}

#accordionJourney .dx-accordion-item-closed .dx-accordion-item-body {
    height: 0;
    padding: 0;
}

app-alert-msg .p-dialog,
app-modal-msg .p-dialog {
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    max-height: 90%;
    transform: scale(1);
    position: relative;
    border-radius: 10px;
    box-shadow: none;
    border: 4px solid var(--modal-color-info);
}

app-alert-msg .font-bold,
app-modal-msg .font-bold {
    font-weight: 700;
    font-size: 20px;
    padding-left: 6vw;
}

app-alert-msg .p-dialog .p-dialog-content,
app-modal-msg .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #212529;
    padding: 1rem;
    text-align: center;
}

app-alert-msg .circle-icon,
app-modal-msg .circle-icon {
    position: absolute;
    top: -35px;
    left: -40px;
    background-color: var(--modal-color-info);
    border-radius: 53%;
    padding: var(--pad-top) 0 0 var(--pad-left);
    font-size: 40px;
    width: 91px;
    height: 90px;
    color: #fff;
}

app-alert-msg .p-button,
app-modal-msg .p-button {
    span {
        color: #fff;
    }

    background: var(--modal-color-info) !important;
    border: 1px solid var(--modal-color-info) !important;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    transition: background-color .15s,
    border-color .15s,
    box-shadow .15s;
    border-radius: 4px;
}

app-alert-msg .pi-times:before,
app-modal-msg .pi-times:before {
    color: #fff;
}

app-alert-msg .walking-truck,
app-modal-msg .walking-truck {
    animation: walk 2s infinite alternate;
}

app-map-here .p-inputtext {
    width: 19.8vw !important;
    padding: 0.4rem 0.75rem;
    color: #0c919a !important;
}

@media screen and (min-width: 600px) and (max-width: 1367px) {
    app-map-here .p-inputtext {
        width: 22vw !important;
    }
}


app-map-here .buttonArrowLeft {
    border: 0px;
    padding: 5px 0px 10px 10px;
    background-color: #fff;
    font-size: 20px;
    color: #0c919a;
}

app-map-here .buttonArrowRight {
    border: 0px !important;
    padding: 0px 0px 2px 11px !important;
    background-color: #fff !important;
    font-size: 20px;
    color: #0c919a;
    font-size: 25px;
    float: right;
}

app-map-here .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
    background: #49adb3;
}

@keyframes walk {
    0% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(45px);
    }
}

app-map-here .dx-accordion-item {
    overflow: hidden;
    border-top-color: #49adb35c;
    border: 1px solid #bde1e4;
    border-radius: 10px;
    margin-bottom: 5px;
}

app-map-here .dx-accordion-item.dx-state-hover>.dx-accordion-item-title {
    background-color: #fff;
}

app-map-here .dx-accordion-item-title {
    border-right: 0px !important;
}

app-map-here .dx-accordion-item-title {
    border-left: 0px !important;
}

app-map-here .accordion-item-opened>.dx-accordion-item-title {
    background-color: #fff !important;
}

.custom-dialog .p-dialog .p-dialog-header {
    background-color: #34898e !important;
    color: #ffffff !important;
    border-bottom: 1px solid #e0e0e0 !important;
    padding: 15px !important;
}

.custom-dialog .p-dialog-title {
    color: #ffffff !important;
}

.custom-dialog  .p-dialog {
    border-radius: 5px;
    box-shadow: none;
    border: 2px solid #308a90;
}